<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Visa Type</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Visa Type
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('visa-type', 'create')"
                      @click="createVisaType()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Visa Type
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.title"
                      outlined
                      dense  v-on:keyup.enter="searchVisaType" clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      outlined
                      item-text="name" v-on:keyup.enter="searchVisaType" clearable
                      item-value="value"
                      :items="status"
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      :loading="isLoading"
                       @click="searchVisaType"
                       class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>

                  <th><strong>Code</strong></th>
                  <th><strong>Application Type</strong></th>
                  <th><strong>Country</strong></th>

                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="visa_types.length > 0" v-for="item in visa_types">
                    <td class="px-2">
                      <a @click="editVisaType(item)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{item.title }}
                      </a>
                    </td>

                    <td class="px-2">
                        {{item.code}}
                    </td>
                    <td class="px-2">
                        {{item.application_type_text}}
                    </td>
                    <td class="px-2">
                        {{item.country_name}}
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('visa-type', 'edit')">
                              <a @click="editVisaType(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit Visa Type</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('visa-type', 'delete')">
                              <a  @click="deleteVisaType(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="visa_types.length == 0">
                    <td colspan="6" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="visa_types.length > 0"
                  class="pull-right mt-7"
                  @input="getAllVisaType"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="getAllVisaType"></create-or-update>

    </div>
  </v-app>
</template>
<script>
import VisaTypeService from "@/services/visa/type/VisaTypeService";
import CreateOrUpdate from "./CreateOrUpdate";
const visaType=new VisaTypeService;
export default {
  components:{
    CreateOrUpdate
  },
  data(){
    return{
      visa_types:[],
      search:{
        title:'',
        is_active:'',
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      isLoading: false,
      total: null,
      perPage: null,
      page: null,

    }
  },
  methods:{
    createVisaType()
    {
      this.$refs['create-or-update'].createVisaType();
    },
    editVisaType(item){
      this.$refs['create-or-update'].editVisaType(item);
    },
    getAllVisaType(){
      visaType
          .paginate(this.search,this.page)
          .then((response) => {
            this.visa_types=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
          });
    },
    searchVisaType(){
      this.getAllVisaType();
    },
    deleteVisaType(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            visaType
                .delete(item)
                .then((response) => {
                  this.getAllVisaType();
                  this.$snotify.success("Visa Type Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  },
  mounted(){
    this.getAllVisaType();
  },
}
</script>