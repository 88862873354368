<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} visa type</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined :error="$v.visa_type.title.$error"
                  dense
                  v-model="visa_type.title">
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.title" >**{{errors.title[0]}}</span>
              <span class="text-danger" v-if="$v.visa_type.title.$error">This Title field is Required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined
                  dense
                  v-model="visa_type.code">
                <template v-slot:label>
                  Code <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                  :loading="countryLoading"
                  :error="$v.visa_type.country_id.$error"
                  :filter="customFilter"
                  :items="countries"
                  dense
                  item-text="name"
                  item-value="id"
                  outlined
                  prepend-inner-icon="fas fa-users"
                  v-model="visa_type.country_id"
              >
                <template v-slot:label>
                  Country <span class="text-danger">*</span>
                </template>
                <template v-slot:item="slotProps">
                  <i :class="`em em-flag-${slotProps.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{slotProps.item.title}}
                </template>
                <template v-slot:selection="data">
                  <i :class="`em em-flag-${data.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{data.item.title}}
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.visa_type.country_id.$error">Country is required</span>
              <span class="text-danger" v-if="errors.country_id" >* {{ errors.country_id[0] }}</span>
            </v-col>
<!--            <pre>{{visa_type}}</pre>-->
            <v-col cols="12" sm="12" md="12">
              <v-select label="Application Type" :items="application_types" item-text="title" item-value="id"
                        outlined
                        dense
                        v-model="visa_type.application_type_id">
                <template v-slot:label>
                  Application Type <span class="text-danger">*</span>
                </template>
                <span class="text-danger" v-if="$v.visa_type.application_type_id.$error">This Application Type field is Required</span>
              </v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <label>
                <strong>Description</strong>
              </label>
              <ckeditor :config="editorConfig"
                        v-model="visa_type.description" >
              </ckeditor>
            </v-col>

<!--            <v-col cols="12" sm="12" md="4">
              Status
              <v-switch
                  v-model="visa_type.is_active"
                  :label="visa_type.is_active ? 'Active' : 'InActive'"
              ></v-switch>
            </v-col>-->

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn v-if="checkIsAccessible('visa-type', 'create') || checkIsAccessible('visa-type', 'edit')"
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import CountryService from "@/services/country/CountryService";
import ApplicationTypeService from "@/services/application/type/ApplicationTypeService";
import {required, requiredIf} from "vuelidate/lib/validators";
import VisaTypeService from "@/services/visa/type/VisaTypeService";

const country=new CountryService();
const applicationType = new ApplicationTypeService();
const visaType=new VisaTypeService;
export default {
  validations:{
    visa_type:{
      title:{required},
      application_type_id:{required},
      country_id:{required},
    }
  },
  data(){
    return{
      title:'',
      dialog: false,
      edit: false,
      loading: false,
      countryLoading: false,
      errors:[],
      visa_type:{
        title:'',
        description:'',
        application_type_id:'',
        country_id:'',
        is_active:true
      },
      application_types:[],
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      countries:[],
    }
  },
  mounted() {
    this.getCountries();
    this.getApplicationType();
    this.getCountries();
  },
  methods:{
    getApplicationType(){
      applicationType.all().then(response=>{
        this.application_types = response.data.application_types
      }).catch(error=>{

      }).finally(this.loading = false)
    },
    closeDialog(){
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {

      this.dialog = true;
    },
    createVisaType(){
      this.openDialog();
      this.resetForm();
      this.title = "Create";
      this.edit=false;
    },
    editVisaType(item){
      this.openDialog();
      this.edit=true;
      this.visa_type=item;
      this.title = "Edit";
    },
    resetForm(){
      this.visa_type={
        title:'',
        code:'',
        description:'',
        country_id:'',
        application_type:'',
        is_active:true,
      };
      this.errors=[];
    },
    getCountries(){
      country
          .getAllCountry({'is_operating_country':1})
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {
            // console.log(error);
          })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },  customFilter(item, queryText, itemText) {
      const textOne = item.title.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1
    },
    _create: function () {
      this.loading = true;
      visaType
          .create(this.visa_type)
          .then(response => {
            this.$snotify.success("Visa Type created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      visaType
          .update(this.visa_type.id, this.visa_type)
          .then(response => {
            this.$snotify.success("Visa Type updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })

    },
  },

}
</script>
